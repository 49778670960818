.results-widget {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.results-widget__nutrient {
    height: 34px;
    display: flex;
    flex-direction: row;
    border: 1px solid #bec8c2;
    border-radius: 5px;
    align-items: center;
}

.results-widget__nutrient > .results-widget__item:nth-child(2) {
    padding-left: 10px;
}

.results-widget__nutrient > .results-widget__item:last-child {
    padding-right: 10px;
}

.results-widget__name {
    height: 34px;
    width: 44px;
    color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    position: relative;
    left: -1px;
}

.results-widget__nutrient--n {
    border-color: #44aa6c;
    background-color: rgba(68, 170, 108, 0.1);
}

.results-widget__nutrient--n > .results-widget__name {
    background-color: #44aa6c;
}

.results-widget__nutrient--p {
    border-color: #e39153;
    background-color: rgba(227, 145, 83, 0.1);
}

.results-widget__nutrient--p > .results-widget__name {
    background-color: #e39153;
}

.results-widget__nutrient--ghg {
    border-color: #4285f4;
    background-color: rgba(66, 133, 244, 0.1);
}

.results-widget__nutrient--ghg > .results-widget__name {
    background-color: #4285f4;
}

.results-widget__divider {
    margin-left: 5px;
    margin-right: 5px;
}

.results-widget__value {
    font-weight: 600;
    color: #58585a !important;
}

.results-widget__uom {
    font-size: 0.9rem;
    margin-left: 4px;
    color: #58585a !important;
}

.results-widget__version {
    font-weight: 600;
    margin-right: 3px;
    color: #58585a !important;
}

.circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: rgb(44, 108, 128);
}

.horizontal-plus {
    position: relative;
    background-color: #ffffff;
    width: 50%;
    height: 12.5%;
    left: 25%;
    top: 43.75%;
}
.vertical-plus {
    position: relative;
    background-color: #ffffff;
    width: 12.5%;
    height: 50%;
    left: 43.75%;
    top: 12.5%;
}

.Arithmetic-symbols {
    font-size: 40px;
    margin-right: 15px;
    color: rgb(44, 108, 128);
}

.NutrientPanelSmall {
    min-width: 140px;
    max-width: 140px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0 10px 5px 10px;
    border: 1px solid #bec8c2;
    border-radius: 5px;
    position: relative;
}

.NutrientPanelMedium {
    min-width: 200px;
    max-width: 250px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0 10px 5px 10px;
    border: none;
    position: relative;
}

.NutrientPanel {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0 10px 5px 10px;
    border: none;
    position: relative;
}