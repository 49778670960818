.breadcrumb {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.breadcrumb li {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.9rem;
}

.breadcrumb li:not(:last-child):after {
    font-family: "overseer" !important;
    content: "/";
    color: #509bc2;
    padding-left: 8px;
    padding-right: 8px;
}

.breadcrumb li i {
    font-size: 14pt;
}

.breadcrumb li a {
    text-transform: none !important;
    font-weight: 500;
}

.breadcrumb li a::before {
    font-size: 14pt;
    margin-left: -3px;
    font-weight: 500;
}
