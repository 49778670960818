.react-datepicker__day-names {
    display: none !important;
}

.react-datepicker__header__dropdown {
    padding-bottom: 0.5em;
}

.u-table-reset-overflow {
    overflow-x: initial !important;
    overflow-y: initial !important;
}

.react-datepicker__close-icon {
    display: block !important;
}
