.mapGrid {
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    flex: 1;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto 450px;
    grid-template-rows: 65vh;
    grid-template-areas: "map layers";
}

.mapGrid_map {
    grid-area: map;
}

.mapGrid_details {
    grid-area: layers;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 1px;
    overflow-y: auto;
}

.mapGrid_details .Grid,
.mapGrid_details .Grid-cell {
    margin: 0 !important;
    padding: 0 !important;
}

.mapGrid_details h3 {
    margin-top: 7.5px;
}

@media (max-width: 1280px) {
    .mapGrid {
        grid-template-columns: 1fr;
        grid-template-rows: 65vh auto;
        grid-template-areas:
            "map"
            "layers";
    }

    .mapGrid_map {
        margin-bottom: 10px;
    }

    .mapGrid_details {
        padding-right: 0;
    }
}

.mapWrapper {
    height: 55vh;
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    flex: 1;
}

.map {
    height: 100%;
    border-radius: 5px;
}

.map__invalid .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.map__invalid .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.map__invalid .gm-style .gm-style-iw-c,
.map__invalid .gm-style .gm-style-iw-t::after {
    background: #f8edec;
}

.map__invalid .gm-style-iw button.gm-ui-hover-effect {
    display: none !important;
}

.mapContainer {
    flex: 1;
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 500px auto;
    grid-template-rows: 85vh;
    grid-template-areas: "layers map";
}

.mapContainer--mapOnly {
    grid-template-rows: auto;
    grid-template-areas: "map map";
}

.mapContainer__layers {
    grid-area: layers;
    padding-right: 10px;
    padding-bottom: 1px;
    overflow-y: auto;
}

.mapContainer__layers .Grid,
.mapContainer__layers .Grid-cell {
    margin: 0 !important;
    padding: 0 !important;
}

.mapContainer__layers h3 {
    margin-top: 7.5px;
}

.mapContainer__map {
    grid-area: map;
}

@media (max-width: 1024px) {
    .mapContainer {
        grid-template-columns: 1fr;
        grid-template-areas:
            "map"
            "layers";
    }

    .mapContainer--mapOnly {
        grid-template-areas:
            "map"
            "map";
    }

    .mapContainer__layers {
        padding-right: 0;
    }

    .mapContainer__map {
        margin-bottom: 10px;
    }
}

.mapButton {
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 3px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.mapButton > .icon {
    font-size: 30px;
    color: #666;
}

.mapButton > .icon:hover {
    color: #333;
}

.mapButton--left {
    margin-left: 10px;
}

.mapButton--right {
    margin-right: 10px;
}

.mapButton--disabled {
    cursor: not-allowed;
    background-color: #ccc;
}

@media print {
    .mapButton {
        display: none !important;
    }
}

.mapLayer {
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    flex-grow: 1;
    background-color: rgba(255, 255, 255, 0.5);
}

.mapLayer + .mapLayer {
    margin-top: 15px;
}

.mapLayer__header {
    display: flex !important;
    align-items: center !important;
    padding: 0 10px;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #e5e9e7;
    background-color: #f8f9f8;
    cursor: pointer;
}

.mapLayer__header::before {
    content: "";
    position: relative;
    left: -11px;
    width: 6px;
    height: 40px;
    border-top-left-radius: 5px;
    background-color: var(--layer-color);
}

.mapLayer__body {
    padding: 10px 10px 10px 10px;
}

.mapLayer__body > .Field:first-child,
.mapLayer__body > .Alert:first-child {
    margin-top: 5px !important;
}

.mapError {
    font-size: 1rem;
    line-height: 1.28571;
    color: #973b36;
    background-color: #f8edec;
}

.mapLabel {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    padding: 5px 10px;
    background-color: white;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.1);
    color: black;
    transform: translate(-50%, -85%);
    opacity: 0.8;
}

.mapLabel.mapErrorLabel {
    background-color: #f8edec !important;
    color: #973b36 !important;
    opacity: 1 !important;
}
