.blockSoils_soils_wrapper {
    margin: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
}

.blockSoils_soils {
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 10px;
    /*position: absolute;
    top: 10px;
    left: 40px;
    z-index: 20;*/
    /*
    */
}

.blockSoils_soils h3 {
    margin-top: 0;
    margin-bottom: 5px;
}

.blockSoils_slider {
    background-color: #fff;
    margin: 5px 10px 0 0;
    padding: 7.5px;
    padding-top: 10px;
    border-radius: 5px;
}

.blockSoils_map {
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    flex: 1;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto 410px;
    grid-template-rows: 80vh;
    grid-template-areas: "map soils";
}

.blockSoils_map_mapOnly {
    grid-template-columns: auto;
    grid-template-areas: "map";
}

.blockSoils_map_map {
    grid-area: map;
}

.blockSoils_map_soils {
    grid-area: soils;
    display: flex;
}

/*@media (max-width: 1024px) {
    .blockSoils_map {
        grid-template-columns: 1fr;
        grid-template-rows: 50vh auto;
        grid-template-areas:
            "map"
            "soils"
    }

    .blockSoils_map_map {
        margin-bottom: 10px;
    }

    .blockSoils_map_soils {
        padding-right: 0;
    }
}*/

.smapSoilList {
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.smapSoilList--noData {
    padding: 10px;
    margin-top: 0;
}

.smapSoilList h3 {
    margin-top: 0;
}

.smapSoilList .Table {
    overflow-y: scroll;
}

.mapWrapper {
    height: 50vh;
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    flex: 1;
}

.map {
    height: 100%;
    border-radius: 5px;
}

.map__invalid .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.map__invalid .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.map__invalid .gm-style .gm-style-iw-c,
.map__invalid .gm-style .gm-style-iw-t::after {
    background: #f8edec;
}

.mapContainer {
    flex: 1;
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 500px auto;
    grid-template-rows: 50vh;
    grid-template-areas: "layers map";
}

.mapContainer--mapOnly {
    grid-template-rows: auto;
    grid-template-areas: "map map";
}

.mapContainer__layers {
    grid-area: layers;
    padding-right: 10px;
    padding-bottom: 1px;
    overflow-y: auto;
}

.mapContainer__layers .Grid,
.mapContainer__layers .Grid-cell {
    margin: 0 !important;
    padding: 0 !important;
}

.mapContainer__layers h3 {
    margin-top: 7.5px;
}

.mapContainer__map {
    grid-area: map;
}

@media (max-width: 1024px) {
    .mapContainer {
        grid-template-columns: 1fr;
        grid-template-areas:
            "map"
            "layers";
    }

    .mapContainer--mapOnly {
        grid-template-areas:
            "map"
            "map";
    }

    .mapContainer__layers {
        padding-right: 0;
    }

    .mapContainer__map {
        margin-bottom: 10px;
    }
}

.mapButton {
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 3px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.mapButton > .icon {
    font-size: 30px;
    color: #666;
}

.mapButton > .icon:hover {
    color: #333;
}

.mapButton--left {
    margin-left: 10px;
}

.mapButton--right {
    margin-right: 10px;
}

@media print {
    .mapButton {
        display: none !important;
    }
}

.mapLayer {
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    flex-grow: 1;
    background-color: rgba(255, 255, 255, 0.5);
}

.mapLayer + .mapLayer {
    margin-top: 15px;
}

.mapLayer__header {
    display: flex !important;
    align-items: center !important;
    padding: 0 10px;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #e5e9e7;
    background-color: #f8f9f8;
    cursor: pointer;
}

.mapLayer__header::before {
    content: "";
    position: relative;
    left: -11px;
    width: 6px;
    height: 40px;
    border-top-left-radius: 5px;
    background-color: var(--layer-color);
}

.mapLayer__body {
    padding: 10px 10px 10px 10px;
}

.mapLayer__body > .Field:first-child,
.mapLayer__body > .Alert:first-child {
    margin-top: 5px !important;
}

.mapError {
    font-size: 1rem;
    line-height: 1.28571;
    color: #973b36;
    background-color: #f8edec;
}
