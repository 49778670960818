:root {

/* colours */
--slate-grey-30: #566a89;
--slate-grey-60: #4c576c;
--slate-grey-90: #364052;

--blue-90: #5386A0;
--blue-60: #509bc2;
--blue-30: #84b9d4;
--blue-10: #e5f0f6;

--green-80: #36975c;
--green-60: #44aa6c;
--green-30: #c7e5d3;
--green-10: #ecf6f0;

--orange-80: #a45b2b;
--orange-60: #ca5e0c;
--orange-30: #e39153;
--orange-10: #fcf4ed;

--red-90: #973b36;
--red-60: #cc4139;
--red-30: #f97a73;
--red-10: #f8edec;

--black: #000;
--neutral-90: #080808;
--neutral-80: #333333;
--neutral-70: #58585A;
--neutral-60: #777777;
--neutral-50: #8d9590;
--neutral-40: #BEC8C2;
--neutral-30: #E5E9E7;
--neutral-20: #F8F9F8;
--white: #fff;

--focus: #4285F4;

}