.CalvingRange {
    padding: 15px 0 0 22px;
}

.CalvingRange-item {
    margin-top: 10px;
    width: 100%;
    padding: 7.5px 15px;
    border: 1px solid #bec8c2;
    border-radius: 5px;
    margin-right: 30px;
}

.CalvingRange-item.CalvingRange-item--add {
    text-align: center;
    border: 2px dashed #8d9590;
}

.CalvingRange-item.CalvingRange-item--add img {
    margin-top: 40px;
    margin-bottom: -7.5px;
}

.CalvingRange-value {
    color: #666666;
    font-size: 3.14286rem;
    line-height: 1.31818;
}

.SoilRange-value span {
    font-size: 1.42857rem;
}

.CalvingRange-name {
    font-weight: 600;
    color: #509bc2;
    font-size: 1.14286rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 15px;
}

.CalvingRange-item--title {
    font-weight: bold;
    font-size: 1.14286rem;
    padding-bottom: 5px;
}

.CalvingRange-slider {
    padding: 40px 27px 0 22px;
    margin-bottom: 30px;
}

.CalvingRange-item--yellow::before {
    background-color: #ffba49;
}

.CalvingRange-item--orange::before {
    background-color: #f87666;
}

.CalvingRange-item--tan::before {
    background-color: #e39153;
}

.CalvingRange-item--red::before {
    background-color: #ad343e;
}

.CalvingRange-item--brick::before {
    background-color: #973b36;
}

.CalvingRange-item--magenta::before {
    background-color: #c84273;
}

.CalvingRange-item--grape::before {
    background-color: #8f5687;
}

.CalvingRange-item--purple::before {
    background-color: #7d63a5;
}

.CalvingRange-item--navy::before {
    background-color: #4c576c;
}

.CalvingRange-item--blue::before {
    background-color: #395998;
}

.CalvingRange-item--sky::before {
    background-color: #509bc2;
}

[class*="CalvingRange-item--"] {
    position: relative;
}

[class*="CalvingRange-item--"]::before {
    content: "";
    position: absolute;
    width: 6px;
    left: -1px;
    top: 0;
    bottom: -1px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.react-contexify {
    z-index: 1000;
}

@media (min-width: 1600px) {
    .Container {
        max-width: 140.28571rem;
    }
}

.react-contexify__item__data {
    width: 100%;
}

td.td-nested {
    background-color: rgba(80, 155, 194, 0.1);
}

.Table-td--blue {
    background-color: #f0f3f9;
}

.Table-td--red {
    background-color: #faeff0;
}

.Table-td--green {
    background-color: #d7f5e3;
}

.Table-td--orange {
    background-color: #eee7d1;
}

.Colour--orange {
    color: #ca5e0c !important;
}

.u-tbl-fix {
    table-layout: fixed;
}

.u-tbl-lg {
    min-width: 1200px;
}

.UtilityNav-user-token .icon {
    font-size: 2em;
    margin-top: 0.8px;
    margin-left: 1px;
}

.Modal-subhead {
    margin-left: -20px;
    margin-right: -20px;
}

.Calendar-reportingyears td {
    background-color: #fff;
    border-right-color: #e5e9e7;
    border-right-width: 1px;
    border-top: none;
}

.Year1 {
    background-color: rgba(80, 155, 194, 0.1) !important;
}

.In-error {
    color: #973b36;
    background-color: #f8edec;
    padding: 15px 15px;
}

.u-font-red {
    color: #973b36;
}

.u-font-grey {
    color: #333333;
}

.u-font-lightgrey {
    color: #666;
}

.u-font-blue {
    color: var(--blue-60);
}

.u-font-white {
    color: #ffffff;
}

.u-font-green {
    color: #36975c;
}

.Calendar-reportingyears .Solid-border {
    border-right-color: #bec8c2;
}


@media print {
    .Table {
        border-radius: 3px;
        border: 1px solid #e5e9e7;
    }

    .Farm-trends{
        width: 520px !important;
    }

    .Table th:last-child {
        border-right: none;
    }

    .Table tbody:not(:first-child) th {
        border-top: 1px solid #e5e9e7;
    }

    .u-print-none {
        display: none !important;
    }

    .u-print-width1of1 {
        width: 100% !important;
    }

    .u-print-noborder {
        border: none;
    }

    .u-print-white {
        background-color: #ffffff !important;
    }

    .u-print-border {
        border: 1px solid #e5e9e7;
        border-radius: 5px;
    }

    .u-print-nopadding {
        padding: 0 !important;
    }

    .ColourPanel {
        min-width: 200px;
        max-width: 200px;
        margin: 5px;
        margin-bottom: 0;
        padding: 5px;
        border-radius: 3px;
    }

    .ColourPanel h5 {
        font-size: 11px;
    }

    .u-page-break {
        break-before: always;
        page-break-before: always;
        break-after: always;
        page-break-after: always;
    }

    .u-page-break-before {
        -webkit-break-before: always;
        break-before: always;
        page-break-before: always;
    }

    .u-page-break-avoid {
        -webkit-break-inside: avoid-page;
        break-inside: avoid-page;
        page-break-inside: avoid;
    }

    .u-page-break-none {
        -webkit-break-after: avoid;
        break-after: avoid;
        page-break-after: avoid;
    }

    .SubMenu-item.is-active a {
        font-size: 1.14286rem;
    }

    .SubMenu-item:not(.is-active) a {
        display: none;
    }

    .FarmTable {
        border-left: none;
    }

    .PageHead .FarmTitle {
        width: 80%;
        max-width: 80%;
    }

    .PageHead-budgetOverview {
        text-align: right;
    }

    .PageHead-budgetOverview span::before {
        background: none;
    }

    .NutrientsLost {
        align-self: flex-end;
    }
}

@media (max-width: 1450px) and (min-width: 1280px) {
    .SoilRange-item {
        padding: 32px 15px;
        min-height: 155px;
    }
}

.Browser-support {
    border-radius: 15px;
    background-color: #eeeeee;
    border: 2px solid #bec8c2;
    width: 600px;
    height: 350px;
}

.bs-title {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.5;
}

.bs-icon {
    padding: 0 15px 0 15px;
}

.bs-icon > img {
    width: 100px;
    height: 100px;
}

.bs-icon > span {
    display: block;
    font-weight: bold;
}

.Support-area textarea {
    height: 300px;
}

.NutrientsLost-indicator.nloss {
    background-color: #44aa6c;
}

.NutrientsLost-indicator.ploss {
    background-color: #e39153;
}

.NutrientsLost-indicator.ghg {
    background-color: #4285f4;
}

.N-color {
    color: #44aa6c;
}

.P-color {
    color: #e39153;
}

.GHG-color {
    color: #4285f4;
}

.Soil-Block-table {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
}

.Button--google {
    background-color: #1a73e8;
    color: #fff;
    width: 100%;
    display: flex;
    border: none;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 5px 15px 8px 0;
}

.Button--google > .icon {
    background-image: url("./images/social-google.svg");
    background-repeat: no-repeat;
    display: inline-block;
    margin: 0 4px 0 4px;
    height: 42px;
    width: 42px;
    flex-grow: 1;
}

.Button--google > .text {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    margin-top: 12px;
}

.Button--facebook {
    background-color: #3b5998;
    color: #fff;
    width: 100%;
    display: flex;
    border: none;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 8px 15px 8px 4px;
}

.Button--facebook > .icon {
    background-image: url("./images/social-facebook.svg");
    background-repeat: no-repeat;
    display: inline-block;
    margin: 0 4px 0 4px;
    height: 37px;
    width: 37px;
    flex-grow: 1;
}

.Button--facebook > .text {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    margin-top: 8px;
}

.Tile--indent {
    margin-left: 33px !important;
}

.u-bg-white {
    background-color: #fff !important;
}

.u-bg-green {
    background-color: #36975c !important;
}

.u-bg-orange {
    background-color: #e39153 !important;
}

.u-bg-midblue {
    background-color: #566a89 !important;
}

.u-bg-skyblue {
    background-color: #509bc2 !important;
}

.u-bg-grey {
    background-color: #d8deda !important;
}

.u-width-350 {
    width: 350px;
}

.u-bg-nloss {
    background-color: #44aa6c !important;
}

.u-bg-nloss--lite {
    background-color: #effcef !important;
}

.u-bg-ploss {
    background-color: #e39153 !important;
}

.u-bg-ploss--lite {
    background-color: #ffe8d6 !important;
}

.u-bg-ghg {
    background-color: #4285f4 !important;
}

.u-bg-ghg--lite {
    background-color: #e1f2fb !important;
}

.u-overflow-hidden {
    overflow: hidden;
}

.u-overflow-visible {
    overflow: visible !important;
}

.u-overflowy-auto {
    overflow-y: auto;
}

.u-nobox-shadow {
    box-shadow: none !important;
}

.darkblue > .Tile-body > .FarmTable_wrapper > ul.hover:hover,
.darkblue > div > .Tile-body > .FarmTable_wrapper > ul.hover:hover,
.darkblue > .Tile-body > .FarmTable_wrapper > ul.selected,
.darkblue > div > .Tile-body > .FarmTable_wrapper > ul.selected {
    background-color: rgba(54, 64, 82, 0.1);
}

.green > .Tile-head,
.green > div > .Tile-head,
.green > .FarmTableGroup-heading,
.green > div > .FarmTableGroup-heading {
    background-color: #36975c !important;
}

.green > .Tile-body > .FarmTable_wrapper > ul.hover:hover,
.green > div > .Tile-body > .FarmTable_wrapper > ul.hover:hover,
.green > .Tile-body > .FarmTable_wrapper > ul.selected,
.green > div > .Tile-body > .FarmTable_wrapper > ul.selected {
    background-color: rgba(54, 151, 92, 0.1);
}

.midblue > .Tile-head,
.midblue > div > .Tile-head,
.midblue > .FarmTableGroup-heading,
.midblue > div > .FarmTableGroup-heading {
    background-color: #566a89 !important;
}

.midblue > .Tile-body > .FarmTable_wrapper > ul.hover:hover,
.midblue > div > .Tile-body > .FarmTable_wrapper > ul.hover:hover,
.midblue > .Tile-body > .FarmTable_wrapper > ul.selected,
.midblue > div > .Tile-body > .FarmTable_wrapper > ul.selected {
    background-color: rgba(86, 106, 137, 0.1);
}

.skyblue > .Tile-head,
.skyblue > div > .Tile-head,
.skyblue > .FarmTableGroup-heading,
.skyblue > div > .FarmTableGroup-heading {
    background-color: #509bc2 !important;
}

.skyblue > .Tile-body > .FarmTable_wrapper > ul.hover:hover,
.skyblue > div > .Tile-body > .FarmTable_wrapper > ul.hover:hover,
.skyblue > .Tile-body > .FarmTable_wrapper > ul.selected,
.skyblue > div > .Tile-body > .FarmTable_wrapper > ul.selected {
    background-color: rgba(80, 155, 194, 0.1);
}

.orange > .Tile-head,
.orange > div > .Tile-head,
.orange > .FarmTableGroup-heading,
.orange > div > .FarmTableGroup-heading {
    background-color: #e39153 !important;
}

.orange > .Tile-body > .FarmTable_wrapper > ul.hover:hover,
.orange > div > .Tile-body > .FarmTable_wrapper > ul.hover:hover,
.orange > .Tile-body > .FarmTable_wrapper > ul.selected,
.orange > div > .Tile-body > .FarmTable_wrapper > ul.selected {
    background-color: rgba(227, 145, 83, 0.1);
}

.nloss > .Tile-head,
.nloss > div > .Tile-head,
.nloss > .FarmTableGroup-heading,
.nloss > div > .FarmTableGroup-heading {
    background-color: #44aa6c !important;
}

.ploss > .Tile-head,
.ploss > div > .Tile-head,
.ploss > .FarmTableGroup-heading,
.ploss > div > .FarmTableGroup-heading {
    background-color: #e39153 !important;
}

.ghg > .Tile-head,
.ghg > div > .Tile-head,
.ghg > .FarmTableGroup-heading,
.ghg > div > .FarmTableGroup-heading {
    background-color: #4285f4 !important;
}

.error > .Tile-head,
.error > div > .Tile-head,
.error > .FarmTableGroup-heading,
.error > div > .FarmTableGroup-heading {
    background-color: #973b36 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.Tile-body-loading {
    cursor: wait;
}

.Tile-body-loading > .Loading-text {
    color: #58585a !important;
    font-size: 1.4em;
    display: flex;
}

span.read-only {
    display: block;
    color: #58585a;
    cursor: default;
}

span.editable {
    display: block;
    padding: 7.5px 15px;
    box-shadow: 0 0 0 1px #e5e9e7;
    border: solid 1px #e5e9e7;
    border-radius: 5px;
    color: #58585a;
    cursor: text;
}

.u-white-space-normal {
    white-space: normal !important;
}

.Print-Table {
    padding-top: 1px;
    padding-left: 1px;
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: 100%;
}

.Print-Table table {
    width: 100%;
    border-collapse: collapse;
}

.Print-Table th,
.Print-Table td {
    padding-top: 4px;
    padding-bottom: 3px;
}

.Print-Table th:not(:first-child),
.Print-Table td:not(:first-child) {
    padding-left: 10px;
}

.Print-Table th {
    white-space: nowrap;
    text-align: left;
}

.Print-Table td {
    padding: 2px 15px 2px 15px;
}

.Print-Table th:empty::before {
    content: "\00a0";
}

@media print {
    .Print-Table th,
    .Print-Table td {
        padding-top: 2px;
        padding-bottom: 1px;
    }
}

.u-border {
    border-color: #e5e9e7;
    border-width: 1px;
    border-radius: 3px;
    border-style: solid;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075);
}

.u-border.rounded {
    border-radius: 12px;
}

.u-border-t {
    border-top: 1px solid #e5e9e7;
}

.u-border-b {
    border-bottom: 1px solid #e5e9e7;
}

.u-border-s {
    border-left: 1px solid #BEC8C2;
}

.u-border-e {
    border-right: 1px solid #e5e9e7;
}

.u-error-box {
    border: 1px solid #973b36 !important;
    border-radius: 6px;
    box-shadow: none;
    color: #973b36;
    background-color: #f8edec;
    padding: 15px;
    font-size: 1rem;
    line-height: 1.28571;
    margin-top: 15px;
}

.Nvp-Value {
    text-align: right;
    font-weight: 500;
}

.Field-mt-sm {
    margin-top: 15px;
}

.u-shaded {
    background-color: #f8f9f8;
}

.u-scroll-x {
    overflow-x: auto !important;
}

.Table-Compressed {
    overflow: hidden;
    position: relative;
}

.Table-Compressed td,
.Table-Compressed th {
    padding: 2px 5px;
    line-height: 1.5;
}

.Table-Compressed .td--keyValuePair.td--keyValuePair {
    padding-top: 0;
    padding-bottom: 0;
}

.Table-Compressed .Calendar-slot.Calendar-slot {
    padding: 2px;
}

.Table-Compressed th {
    padding-top: 4px;
    padding-bottom: 4px;
}





.Table-Grid {
    overflow: hidden;
    position: relative;
}

.Table-Grid td,
.Table-Grid th {
    padding: 0px 2px 0 4px;
    line-height: 1.5;
}

.Table-Grid th {
    padding-top: 0px;
    padding-bottom: 0px;
}




.inline-key {
    font-size: 0.95rem;
    line-height: 1.66667;
    font-weight: 600;
}

.inline-value {
    font-size: 0.95714rem;
    line-height: 1.66667;
}

.u-icon-md {
    height: 40px;
    width: 40px;
}

.u-icon-sm {
    height: 35px;
    width: 35px;
}

.u-title-md {
    font-size: 1.5rem;
    max-width: calc(100% - 8px);
}

.u-title-sm {
    font-size: 1.2rem;
    max-width: calc(100% - 8px);
}

.u-font-600 {
    font-weight: 600;
}

.u-maxw-350 {
    max-width: 350px;
}

.u-minw-310 {
    min-width: 310px;
}

.u-minw-400 {
    min-width: 400px;
}

.u-maxh-400 {
    max-height: 400px;
}

.u-maxh-600 {
    max-height: 600px;
}

.u-maxh-800 {
    max-height: 800px;
}

.u-maxh-1000 {
    max-height: 1000px;
}

.u-border-1 {
    border: 1px solid #bec8c2;
}

.u-border-none {
    border: none !important;
}

.u-borderTop-none {
    border-top: none !important;
}

.u-print-show {
    display: none !important;
    font-size: 10px;
}

.u-print-flex {
    display: none !important;
}

.PageHead-Print {
    display: none;
    font-size: 14px;
}

.PageHead-PrintLogo {
    display: none;
}

.BlockTitle {
    display: flex;
    justify-content: space-between;
}

@media print {
    .u-minw-400 {
        min-width: 295px;
    }

    .Header {
        height: 100px;
    }

    .PageHead-Print,
    .u-print-show {
        display: block !important;
    }

    .u-print-flex,
    .PageHead-PrintLogo {
        display: flex !important;
    }

    .PageHead > .Container {
        justify-content: space-between;
    }

    .Tile-body {
        padding-left: 5px;
    }

    body {
        zoom: 80%;
    }

    .Calendar-Compressed {
        zoom: 70%;
    }

    .PageHead-budgetOverview {
        width: 100% !important;
        max-width: 100% !important;
        text-align: left !important;
        padding-left: 20px;
        padding-top: 20px;
    }
}

.u-pos-rel {
    position: relative;
}

.u-min-height-0 {
    min-height: 0 !important;
}

.Watermark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-repeat: repeat;
    opacity: 0.05;
    background-size: 260px;
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
    pointer-events: none;
}

:has(> .Watermark) {
    overflow: hidden;
    position: relative;
}

.u-textLower {
    text-transform: lowercase !important;
}

.u-textCapitalise {
    text-transform: capitalize !important;
}

.ovr_multiSelect__control {
    padding: 5px 0 3px 0 !important;
    /*border-color: #e5e9e7 !important;*/
}

.ovr_multiSelect__control--is-focused {
    border-color: #36975c !important;
    box-shadow: 0 0 0 1px #36975c !important;
}

.Field.has-error .ovr_multiSelect__control {
    border-color: #973b36 !important;
    box-shadow: none !important;
}

.ovr_multiSelect__menu {
    position: relative !important;
}

.ovr_multiSelect__group .ovr_multiSelect__option {
    padding-left: 30px !important;
}

.ovr_multiSelect__multi-value__label .icon,
.ovr_multiSelect__group .ovr_multiSelect__option .icon {
    position: relative;
    top: 5px;
    line-height: 35%;
}

.ovr_multiSelect__option {
    font-weight: 500 !important;
}

.ovr_multiSelect__option--is-focused,
.ovr_multiSelect__option:hover {
    background-color: rgba(80, 155, 194, 0.4) !important;
}

.ovr_multiSelect__option--is-selected,
.ovr_multiSelect__option--is-selected:hover,
.ovr_multiSelect__option:active {
    background-color: #509bc2 !important;
    color: #fff !important;
}

.ovr_multiSelect__option--is-disabled:hover,
.ovr_multiSelect__option--is-disabled.ovr_multiSelect__option--is-focused {
    background-color: #fff !important;
}

.ovr_multiSelect__dropdown-indicator,
.ovr_multiSelect__clear-indicator {
    color: #509bc2 !important;
}

.Field.has-error .ovr_multiSelect__placeholder,
.Field.has-error .ovr_multiSelect__clear-indicator,
.Field.has-error .ovr_multiSelect__dropdown-indicator {
    color: #973b36 !important;
}

.ovr_multiSelect__clear-indicator .icon:hover {
    color: #364052 !important;
}

.ovr_multiSelect__multi-value__label {
    color: #fff !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
}

.ovr_multiSelect__multi-value__remove {
    margin-left: 10px;
}

.ovr_multiSelect__multi-value__remove:hover {
    background-color: #fff !important;
    color: #509bc2 !important;
}

.Modal2-wrapper {
    display: -webkit-box;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    z-index: 20;
}

.Modal2 {
    border-radius: 5px;
    display: block;
    position: absolute;
    top: 140px;
    bottom: 50px;
    left: 80px;
    right: 80px;
    border-radius: 5px;
    background-color: #fff;
    min-width: 200px;
    margin: auto;
}

.Modal2.Modal--full {
    top: 50px;
    bottom: 50px;
    left: 50px;
    right: 50px;
}

.Modal2-wrapper.child .Modal2 {
    top: 100px;
    bottom: 60px;
    left: 100px;
    right: 60px;
}

.Modal2-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #44aa6c;
    border-bottom: 1px solid #e5e9e7;
    color: #fff;
    font-weight: 600;
    height: 50px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 0 20px;
    overflow: hidden;
}

.Modal2-wrapper.child .Modal2-header {
    background-color: #509bc2;
}

.Modal2-header--left {
    flex: 1;
}

.Modal2-header--left > .Loader {
    top: 5px;
    margin-left: 15px;
    height: 20px !important;
}

.Modal2-header--left > .Loader * {
    height: 18px !important;
    width: 20px !important;
}

.Modal2-header--right {
}

.Modal2-close {
    -webkit-box-ordinal-group: 100;
    order: 99;
    margin-left: auto;
    color: #ffffff;
}

.Modal2-close.is-active,
.Modal-close:hover,
.Modal-close:active,
.Modal-close:focus {
    color: #ffffff;
}

.Modal2-body {
    position: absolute;
    top: 50px;
    bottom: 61px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px;
    padding-bottom: 20px;
}

.Modal2-body > .Alert {
    margin: 0 -20px;
}

.offline .Modal2-body input,
.offline .Modal2-body select,
.offline .Modal2-body label,
.offline .Modal2-body .Field {
    pointer-events: none;
}

.Modal2-body--alert {
    margin: 0 -20px;
}

.Modal2-body--alert .Alert {
    margin: 0;
}

.Modal2-footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e5e9e7;
    background-color: #fff;
    padding: 8px 20px;
    overflow: hidden;
}

.Modal2-footer .Button + .Button {
    margin-left: 20px;
}

@media print, (min-width: 529px) {
    .Modal2-footer {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

.Modal2-footer--left {
    flex: 1;
}

.Modal2-footer--right {
    display: flex;
    align-items: center;
}

@media (max-width: 529px) {
    .Modal2-footer--right {
        display: block;
    }

    .Modal2-footer--left .Button {
        width: 100%;
    }

    .Modal2-footer--right .Button {
        margin-left: 0 !important;
        margin-top: 10px !important;
        width: 100%;
    }
}

.Modal2--fluid.Modal2-wrapper {
    overflow-y: scroll;
}

.Modal2--fluid .Modal2 {
    position: relative !important;
    top: initial !important;
    bottom: initial !important;
    left: initial !important;
    right: initial !important;
    width: 95%;
}

.Modal2--fluid .Modal2-body,
.Modal2--fluid .Modal2-footer {
    position: initial !important;
}

.Button > .icon {
    position: relative;
}

.Button > .icon--before {
    left: -12px;
}

.Button > .icon--after {
    left: 12px;
}

.Modal-footer > .ButtonBar {
    padding: 8px 20px 0 20px !important;
    margin: 0 -20px;
}

.Modal {
    padding-bottom: 8px !important;
}

.Modal--notification {
    padding-top: 25px !important;
    padding-bottom: 45px !important;
}

.sticky {
    position: -webkit-sticky;
    position: sticky !important;
    top: 0;
}

.waiting,
.waiting * {
    cursor: wait !important;
}

.waiting .Modal2-close .icon-question,
.waiting .Modal2-close .icon-cross,
.waiting .Modal2-footer #cancel {
    cursor: auto !important;
}

optgroup {
    font-size: 0.95rem;
}

option {
    font-size: 1rem;
    text-transform: none;
}

.Confirmation-field {
    display: inline;
}

.offline .Confirmation-field {
    display: none;
}

.Confirmation-label {
    display: inline !important;
    margin-left: 10px;
    margin-right: 10px;
    text-transform: none;
}

.Confirmation-input {
    background-color: #fff;
    border: 1px solid #bbb;
    border-radius: 3px;
    display: inline;
    height: 34px;
    padding: 5px;
    margin-right: 10px;
}

.offline-not-allowed {
    display: none;
}

.offline .offline-not-allowed {
    display: block;
}

.InfoPanel {
    z-index: 10;
    width: 480px;
    /*top: 151px;*/
    right: 0;
    height: 100vh;
    position: fixed;
    background-color: #fff;
    box-shadow: none;
    padding-top: 24px;
    padding-bottom: 200px;
    will-change: transform, box-shadow;
    transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    box-shadow: 0 2px 24px 0 rgba(52, 64, 84, 0.35);
}

.InfoPanel-body {
    color: #333333;
    margin-top: 8px;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 160px;
    padding-left: 24px;
    padding-right: 24px;
    scrollbar-width: none;
    line-height: 1.89;
}

.InfoPanel-dismiss {
    padding-right: 20px;
}

.Scenario-panel {
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.075);
    margin-top: 15px;
}

.Scenario-panel-head {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px 15px;
    background-color: #ddd;
    font-weight: 600;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.Scenario-panel--closed > .Scenario-panel-head {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.Scenario-panel-body {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px;
}

.Scenario-panel--closed > .Scenario-panel-body {
    display: none;
}

.Global-search {
    position: absolute;
    top: 60px;
    z-index: 21;
    width: 550px;
    right: 0px;
    background-color: #eeeeee !important;
    box-shadow: 0 2px 24px 0 rgba(52, 64, 84, 0.35);
    border-radius: 5px;
    padding-bottom: 10px;
}

.Search-head {
    padding: 10px 5px 10px 15px;
    border-bottom: 1px inset #ccc;
    margin-bottom: 10px;
}

.Search-box {
    margin: 0 5px 0 5px;
}

.Search-box input {
    width: 100%;
    height: 52px !important;
    border-radius: 5px 0 0 5px;
    padding: 0 30px 0 15px;
    outline: none;
    border: 1px solid #ddd;
    border-right: none;
    font-size: 1.1em;
}

.Search-button {
    width: 60px;
    height: 52px;
    border: 1px solid #ddd;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    border-left: none;
    cursor: pointer;
    padding-top: 5px;
}

.Search-options {
    margin: 0px 0 5px 10px;
}

.Search-results {
    background-color: #ffffff;
    margin: 10px;
    padding: 5px 10px 5px 5px;
}

.Search-result {
    padding: 15px 0 15px 0;
    border-top: 1px solid #eeeeee;
}

.Search-result-snippet > em {
    font-style: normal;
    font-weight: 600;
}

.Search-results-cntr {
    overflow-y: auto;
    max-height: 700px;
}

.react-toggle-track svg {
    pointer-events: none;
}

.Help-toggle .react-toggle-track {
    background-color: #ffffff !important;
    width: 60px;
}

.Help-toggle .react-toggle-thumb {
    border-color: #8d9590;
    background-color: #8d9590;
}

.Help-toggle.react-toggle--checked .react-toggle-thumb {
    border-color: #36975c;
    background-color: #36975c;
    left: 37px;
}

.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.Help-toggle .react-toggle-track-check,
.Help-toggle .react-toggle-track-x {
    padding-top: 5px;
    color: #509bc2 !important;
}

.Help-toggle .react-toggle-track-x {
    margin-right: 10px;
}

.My-farm-toggle .react-toggle-track {
    background-color: #509bc2 !important;
}

.My-farm-toggle .react-toggle-thumb {
    border-color: #509bc2;
}

.My-farm-toggle.react-toggle--checked .react-toggle-track {
    background-color: #36975c !important;
}

.My-farm-toggle.react-toggle--checked .react-toggle-thumb {
    border-color: #36975c;
}

.BudgetHeader {
    display: flex;
    justify-content: space-between;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    align-items: center !important;
    flex-wrap: wrap;
}

.BudgetHeader-column {
    display: flex;
    flex-direction: column;
}

.PanelTabs {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25), inset -1px 0 0 0 #d0d6d2;
    background-color: #364052;
    height: 45px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.PanelTabs-hamburger {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
}

.PanelTabs-hamburger-title {
    font-family: "Exo 2", sans-serif;
    margin-left: 5px;
    white-space: nowrap;
}

.PanelTabs-actions {
    display: inline-block;
    color: white;
    padding: 10px 20px 10px 0;
}

.PanelTabs-menu {
    display: none;
}

.PanelTabs-menu .a,
.PanelTabs-menu a {
    white-space: nowrap;
    color: #ffffff;
    padding: 7.5px 15px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.PanelTabs-menu a.is-active,
.PanelTabs-menu a:hover,
.PanelTabs-menu a:active,
.PanelTabs-menu a:focus,
.PanelTabs-menu .a.is-active,
.PanelTabs-menu .a:hover,
.PanelTabs-menu .a:active,
.PanelTabs-menu .a:focus {
    background-color: #509bc2;
}

.PanelTabs-item {
    position: relative;
}

.PanelTabs-item .a,
.PanelTabs-item a {
    color: #bec8c2;
    min-width: 250px;
}

.PanelTabs-item a.is-active,
.PanelTabs-item a:hover,
.PanelTabs-item a:active,
.PanelTabs-item a:focus {
    color: #ffffff !important;
    background-color: #509bc2 !important;
}

.PanelTabs-item.is-active .a,
.PanelTabs-item.is-active a {
    color: #ffffff !important;
    background-color: #509bc2 !important;
}

.PanelTabs-item.is-active a::after {
    /*content: "\e905";
            position: absolute;
            font-family: 'overseer' !important;
            font-size: 18px;
            top: calc(11%);
            left: calc(100% - 25px);*/
}

.PanelTabs.is-open .PanelTabs-menu {
    display: block;
    z-index: 30;
    background-color: #364052;
    position: absolute;
    top: 45px;
    padding-top: 60px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.075);
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

.Panel-action-label {
    display: none;
    white-space: nowrap;
    margin-left: 15px;
}

.PanelTabs.orange {
    background-color: #e39153;
}

.PanelTabs.orange .PanelTabs-item .a,
.PanelTabs.orange .PanelTabs-item a {
    background-color: #e39153;
    color: #fff;
}

.PanelTabs.is-open.orange .PanelTabs-menu {
    background-color: #e39153;
}

@media (min-width: 1280px) {
    .PanelTabs-hamburger {
        display: none;
    }

    .PanelTabs-menu {
        display: -webkit-box;
        display: flex;
        height: 45px;
        overflow: visible;
    }

    .PanelTabs-menu > .PanelTabs-item:first-child,
    .PanelTabs-menu > .PanelTabs-item:first-child a {
        border-top-left-radius: 5px;
    }

    .PanelTabs-item {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        background-color: #364052;
    }

    .PanelTabs-item .a,
    .PanelTabs-item a {
        min-width: initial;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -webkit-box-align: center;
        align-items: center;
        justify-content: space-around;
        color: #bec8c2;
        white-space: nowrap;
        text-decoration: none;
        padding-left: 20px;
        padding-right: 20px;
    }

    .PanelTabs-item a.is-active,
    .PanelTabs-item a:hover,
    .PanelTabs-item a:active,
    .PanelTabs-item a:focus,
    .PanelTabs-item .a:hover,
    .PanelTabs-item .a:focus {
        color: #ffffff !important;
        background-color: #509bc2 !important;
    }

    .PanelTabs-item.is-active .a,
    .PanelTabs-item.is-active a {
        color: #ffffff !important;
        background-color: #509bc2 !important;
    }

    .PanelTabs-item.is-active a:before,
    .PanelTabs-item.is-active .a:before {
        color: inherit;
    }

    .PanelTabs-item.is-active a::after,
    .PanelTabs-item.is-active .a::after {
        position: absolute;
        content: "";
        width: 9px;
        height: 9px;
        top: calc(100% - 1px);
        left: calc(50% - 12.72792px / 2);
        background-color: #509bc2;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        z-index: 10;
    }

    .PanelTabs-item .Todo {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .Panel-action-label {
        display: inline;
    }
}

.u-textTrim {
    overflow: hidden;
    text-overflow: ellipsis;
}

.ScenarioEnterprise-panel {
    border: 2px solid #efefef;
    border-radius: 5px;
    margin-top: 10px;
    overflow: hidden;
}

.ScenarioEnterprise-panel-title {
    font-weight: 600;
    border-bottom: 1px solid #efefef;
    padding: 3px 10px;
    background-color: #efefef;
    margin: 0px;
}

.ScenarioEnterprise-panel-body {
    padding: 0 10px 10px 10px;
}

.ReportPage {
    -webkit-break-inside: avoid-page;
    break-inside: avoid-page;
    page-break-inside: avoid;
}

.printable-chart-container{
    position: relative;
}

.printable-chart{
    height: 300px;
}

@media print {
    .printable-chart {
        width: 100% !important;
        height: 200px !important;
    }
}

.ReportSection + .ReportSection {
    margin-top: 15px;
}

.ReportSection h1 {
    font-size: 22px;
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ReportSection h2 {
    font-size: 18px;
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 10px;
    margin-top: 0;
}

.ReportCover {
    height: 1300px;
    padding: 15px;
    border: 1px solid #efefef;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ReportCoverPage-title {
    display: flex;
    align-self: center;
    font-size: 40px;
    font-weight: 900;
}

@media print {
    .ReportCover {
        margin: 0;
        border: none;
    }

    .Map-container div > img {
        position: absolute;
    }
}

.QuickSearch {
    position: absolute;
    top: 15px;
    z-index: 20;
    min-width: 215px;
}

.QuickSearch.is-searching {
    left: 40vw;
}

.QuickSearch.is-searching input {
    width: 100%;
}

@media (max-width: 1280px) {
    .QuickSearch.is-searching {
        left: 20vw;
    }
}

.QuickSearch-input-wrapper {
    height: 32px;
    border-radius: 5px;
    padding-left: 10px;
    background-color: #fff;
    align-items: center;
    display: flex;
}

.QuickSearch-input {
    border: none;
    outline: none;
}

.QuickSearch-panel {
    margin-top: 5px;
    padding: 10px;
    background-color: #eee !important;
    border-radius: 5px;
    box-shadow: 0 2px 24px 0 rgba(52, 64, 84, 0.35);
}

.QuickSearch-options-list {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
}

.QuickSearch-results {
    max-height: 75vh;
    overflow-y: auto;
    padding-right: 5px;
    margin-top: 10px;
}

.QuickSearch-results-section {
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
}

.QuickSearch-results-section + .QuickSearch-results-section {
    margin-top: 10px;
}

.MapWithList-wrapper {
    display: flex;
    height: 80vh;
}

.MapWithList {
    width: 100%;
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
}

.MapWithList-list-view {
    width: 30%;
    min-width: 450px;
    height: 100%;
    overflow: auto;
    padding-right: 10px;
    margin-right: 10px;
}

.MapWithList-list-view-table {
    min-width: 425px;
}

.MapWithList-list-view tr.is-selected td {
    background-color: rgb(255, 2255, 0, 0.3);
}

.MapWithList-list-view td .HeroPanel {
    margin-top: 10px;
    padding: 7.5px 15px 15px 15px;
    cursor: default;
    margin-bottom: 10px;
}

.Map-wrapper {
    display: flex;
    height: 50vh;
}

.Map {
    width: 100%;
    height: 100%;
}

.MapCustomControl {
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 3px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MapCustomControl > .icon {
    font-size: 30px !important;
    color: #666;
}

.MapCustomControl > .icon:hover {
    color: #333;
}

.Polygon-svg-wrapper {
    height: 210px;
    width: 210px;
    padding: 10px;
}

.Polygon-svg-wrapper > svg {
    height: 200px;
    width: 200px;
    fill: green;
    fill-opacity: 0.6;
    stroke: #00ff00;
}

.hovered {
    background-color: rgba(80, 155, 194, 0.1);
}

.datalist dl {
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

dt {
    float: left;
    width: 25%;
    padding: 0;
    margin: 0;
    padding: 9px 0 5px;
    height: 35px;
    font-weight: 600;
    border-bottom: 1px solid #eee;
}

dd {
    float: left;
    width: 75%;
    padding: 0;
    margin: 0;
    padding: 9px 0 5px;
    height: 35px;
    border-bottom: 1px solid #eee;
}

.SimpleTable {
    padding-top: 1px;
    padding-left: 1px;
    padding-bottom: 10px;
    border-radius: 5px;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
}

.SimpleTable table {
    width: 100%;
    border-collapse: collapse;
    page-break-inside: auto;
}

.SimpleTable th,
.SimpleTable td {
    border-width: 0;
    padding-top: 9px;
    padding-bottom: 8px;
    border-style: solid;
}

.SimpleTable .th,
.SimpleTable th {
    white-space: nowrap;
    text-align: left;
}

.SimpleTable thead:not(:first-child) th {
    border-top-width: 1px;
}

.SimpleTable .th:empty::before,
.SimpleTable th:empty::before {
    content: "\00a0";
}

.SimpleTable td {
    border-top-width: 1px;
    padding-right: 12px;
    border-color: #e5e9e7;
}

.u-pill {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 8px;
    color: white;
    height: 20px;
    font-weight: 600;
    font-size: 0.75rem;
}

.u-pill.info {
    background-color: #36975c;
}

.u-pill.warning {
    background-color: #ca5e0c;
}

.u-pill.error {
    background-color: #ca0c0c;
}

.FarmDashboard-cell {
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-bottom: 15px;
}

.FarmDashboard-svg-wrapper {
    padding: 10px;
}

.FarmDashboard-svg-wrapper > svg {
    fill: #e5e5e5;
    fill-opacity: 0.6;
    stroke: #d9d9d9;
}

.FarmDashboard-cell #text {
    font-size: 16px;
    font-weight: bold;
    font-variant: small-caps;
    font-variant-ligatures: none;
    stroke: black;
    stroke-width: 1.5px;
    fill: white;
}

.Widget {
    display: flex;
    flex-flow: column;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #e5e9e7;
}

.Widget-header {
    display: flex;
    align-items: center;
    align-content: space-between;
    padding-bottom: 15px;
}

.Widget-header-select {
}

.Widget-header-select > .Field {
    margin: 0;
}

.Widget-header-select > .Field > .Select {
    display: flex;
    align-items: center;
}

.Widget-header-select > .Field > .Select select {
    box-shadow: none;
    border: 1px solid #e5e9e7;
    border-radius: 3px;
    padding-left: 10px;
    margin-left: 10px;
    font-weight: 600;
}

.Widget-title-nolink {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0px;
    text-transform: none !important;
    color: #364052;
}

.Widget-title-nolink span {
    color: #080808;
}

.Widget-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0px;
    text-transform: none !important;
    color: #364052;
}

.Widget-title span {
    color: #509bc2;
}

.Widget-content {
    flex: 1;
}

.Widget-content .Alert {
    border-radius: 3px;
}

.Widget-content-title {
    font-size: 1.28571rem !important;
    font-weight: 600;
    margin-bottom: 5px;
}

.Farm-actions {
    padding-inline-start: 10px !important;
}

.Farm-actions li {
    padding-bottom: 20px;
}

.Farm-actions a {
    text-decoration-color: #6e768e;
    color: #6e768e;
}

.tile-head-breadcrumb ul {
    list-style: none;
    margin: 0;
    padding-inline-start: 0;
}

.tile-head-breadcrumb ul li {
    display: inline;
}

.tile-head-breadcrumb ul li + li:before {
    padding: 0 8px 0 8px;
    /*content: "\003e";*/
}

.tile-head-breadcrumb ul li a {
    text-decoration: none;
    color: #364052 !important;
}

.tile-head-breadcrumb ul li a:hover {
    text-decoration: underline;
}

.FarmAreaTotals {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.FarmAreaTotals li {
    padding-left: 40px;
    font-weight: 600;
    font-size: 0.95rem;
    line-height: 1.5;
}

@media (max-width: 1024px) {
    .FarmAreaTotals li.sub-area {
        display: none;
    }
}

.FarmHome-hamburger.RoundButton:before {
    height: 40px;
    width: 40px;
    color: #364052;
    border-radius: 25px;
    margin-right: 5px;
    /*font-size: 14px;
        color: #fff;
        background-color: #364052;
        border: 1px solid #e5e9e7;
        border-radius: 25px;
        padding: 6px;*/
}

.FarmMenu {
    position: absolute;
    top: 122px;
    left: 20px;
    z-index: 20;
}

.FarmMenu-panel {
    margin-top: 5px;
    padding: 10px 20px 0 10px;
    background-color: #fff !important;
    border-radius: 5px;
    box-shadow: 0 2px 24px 0 rgba(52, 64, 84, 0.35);
}

.u-flexSplit {
    display: -webkit-box !important;
    display: flex !important;
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    align-items: center !important;
}

.fieldGroup {
    display: flex;
    grid-gap: 20px;
}

.fieldGroup > .Field {
    flex-grow: 1;
}

.flexWidget {
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    flex-grow: 1;
    background-color: #fff;
}

.flexWidget__header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0 10px;
    height: 40px;
}

.flexWidget__header > h3 {
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.flexWidget__body {
    padding: 0px 15px 15px 15px;
}

.flexWidget__body > .Field:first-child,
.flexWidget__body > .Alert:first-child {
    margin-top: 5px !important;
}

.modelParameterGrouping {
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    flex-grow: 1;
    background-color: #fff;
    margin-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.input-pack {
    border: 1px solid #509bc2;
    border-radius: 4px;
    width: 100%;
    padding: 12px 20px;
    margin: 6px 0 0 0;
    box-sizing: border-box;
    font-size: 1.2rem;
    letter-spacing: 2px;
}

.input-pack.error {
    border-color: #973b36;
}

.Credit-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fafafa;
    padding: 5px 15px 5px 15px;
}

.Credit-card.selected {
    border-color: #47c18f;
    border-width: 2px;
}

.Credit-card.expired {
    border-color: #973b36;
    border-width: 1px;
}

.Credit-card-details {
    text-align: right;
}

.vl {
    border-left: 6px dashed #eee;
}

.farm-plan-circle {
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 50%;
    font-size: 25px;
    color: white;
    text-align: center;
}

.farm-plan-circle-yearend {
    background: #566a89;
}

.farm-plan-circle-plan {
    background: #ca5e0c;
}

.tr-total-line {
    background-color: rgb(255, 237, 204);
}

.email-header {
    font-weight: 400;
    color: rgba(41, 41, 41, 1);
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
}

.u-hover-white:hover,
.u-hover-white:active,
.u-hover-white:focus {
    color: #fff;
}

.glow {
    background-color: #ecf8f1;
}

.u-z-top {
    z-index: 100 !important;
}

.NewsArticle-item {
    background-color: white;
    border-radius: 4px;
    margin-top: 20px;
    padding: 20px;
}

.NewsArticle-item img{
    max-width: 400px;
}

.Search-input {
    display: block;
    width: 100%;
    padding: 0.4rem 0.4rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Search-input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #36975C;
    outline: 0;
}

.u-info-pill {
    display: flex;
    align-items: center;
    padding: 5px 15px 5px 15px; 
    border-radius: 15px;
    color: white;
    font-weight: 600;
    background-color: #509BC2;
}

.u-grey-pill {
    display: flex;
    align-items: center;
    padding: 5px 15px 5px 15px;
    border-radius: 15px;
    color: white;
    font-weight: 600;
    background-color: #999;
}

.Container-pill{
    padding: 8px 12px 8px 8px;
    border-radius: 25px;
    border: 1px solid #d8deda;
    margin-right: 10px;
    margin-bottom: 6px;
    margin-top: 6px;
}

.Filter-btn{
    width: 102px;
}