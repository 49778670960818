.dashboard {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 4fr 5fr;
    grid-auto-rows: auto;
    grid-template-areas: "activeUsers featureUsage";
}

@media (max-width: 1024px) {
    .dashboard {
        grid-template-columns: 1fr;
        grid-template-areas:
            "activeUsers"
            "featureUsage";
    }
}

.activeUsers {
    grid-area: activeUsers;
}

.featureUsage {
    grid-area: featureUsage;
}
