.grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-template-areas:
        "details farmMap"
        "location farmMap"
        "identifiers farmMap";
}

.grid--withOwnership {
    grid-template-areas:
        "details farmMap"
        "location farmMap"
        "ownership farmMap"
        "identifiers farmMap";
}

@media (max-width: 1024px) {
    .grid {
        grid-template-columns: 1fr;
        grid-template-areas:
            "details"
            "location"
            "farmMap"
            "identifiers";
    }

    .grid--withOwnership {
        grid-template-areas:
            "details"
            "location"
            "ownership"
            "farmMap"
            "identifiers";
    }
}

.details {
    grid-area: details;
}

.location {
    grid-area: location;
}

.ownership {
    grid-area: ownership;
}

.identifiers {
    grid-area: identifiers;
}

.farmMap {
    grid-area: farmMap;
    display: flex;
    flex-direction: column;
    height: 60vh;
}

.farmMap > .flexWidget__body {
    display: flex;
    flex-direction: column;
    height: 100%;
}
