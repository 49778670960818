.importMapWrapper {
    margin-top: 20px;
    height: 55vh;
}

.map__deleteInvalid,
.map__deleteSelected {
    display: none !important;
}

.map__deleteInvalid .icon {
    color: #973b36 !important;
    font-size: 26px !important;
}

.map__deleteSelected .icon {
    color: #509bc2 !important;
    font-size: 26px !important;
}

.map__invalid .map__deleteInvalid,
.map__selected .map__deleteSelected {
    display: flex !important;
}

.map {
    height: 100%;
    border-radius: 5px;
}

.map__invalid .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.map__invalid .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.map__invalid .gm-style .gm-style-iw-c,
.map__invalid .gm-style .gm-style-iw-t::after {
    background: #f8edec;
}

.mapError {
    font-size: 1rem;
    line-height: 1.28571;
    color: #973b36;
    background-color: #f8edec;
}
