.irrigation_map {
    border: 1px solid #e5e9e7;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    flex: 1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 75vh;
}
