.NotificationsBell-badge {
    background-color: red;
    color: white;
    border-radius: 15px;
    padding: 0 5px;
    font-size: 12px;
    position: absolute;
    top: 10px;
    left: 38px;
}

.NotificationsBell-list {
    min-width: 60vw;
    padding: 0 7.5px;
}

.NotificationsBell-list-bg {
    background-color: white;
    padding: 5px;
    border-radius: 3px;
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.NotificationsBell-list-bg .Alert {
    font-weight: initial !important;
    white-space: initial !important;
    margin-bottom: 0 !important;
}
